import { AdminContext } from '@/contexts/admin-context';
import { useContext, useEffect, useState } from 'react';

/**
 * Hook that imports an external utils package when the app is loaded as a widget
 */
export const useWidgetModeUtils = () => {
  const [utils, setUtils] = useState(null);
  const [utilsLoaded, setUtilsLoaded] = useState(false);

  const { getWidgetModeEnabled } = useContext(AdminContext);

  // dynamically load the widget utils as the package has no support for Next.js
  const importUtils = async () => {
    const data = await import('@doddle/lui-portal-utilities');

    setUtils(data);
    setUtilsLoaded(true);
  };

  useEffect(() => {
    if (getWidgetModeEnabled()) {
      importUtils();
    } else {
      setUtilsLoaded(true);
    }
  }, []);

  if (!utilsLoaded || !utils) {
    return {};
  }

  return {
    isIFrame: typeof utils.isIFrame === 'function' ? utils.isIFrame() : false,
    getToken: utils.getMSALToken,
    utilsLoaded,
  };
};

export default {
  useWidgetModeUtils,
};
