import TextDiv from '@/components/text-div';
import { ChangesContext } from '@/contexts/changes-context';
import { LocaleContext } from '@/contexts/locale-context';
import React, { useContext, useMemo } from 'react';
import { FormError, Submit } from '@/contexts/validation-context';
import { RoutingContext } from '@/contexts/routing-context';
import { AdminContext } from '@/contexts/admin-context';

function PublishButton() {
  const { translate } = useContext(LocaleContext);
  const {
    areUnsavedChanges, publishChanges, error: publishError, publishingState, changeStatus,
  } = useContext(ChangesContext);
  const { getConfigRefetchDelay } = useContext(RoutingContext);
  const { isNavBarV2 } = useContext(AdminContext);

  const submitButtonText = useMemo(() => {
    switch (publishingState) {
      case changeStatus.PUBLISHING:
        return translate('wl.publishing.publishing');
      case changeStatus.PUBLISHED:
        return translate('wl.publishing.published');
      default:
        return translate('wl.publishing.publish');
    }
  }, [publishingState, translate]);

  const publishButtonFunction = useMemo(() => () => publishChanges({
    updateDelay: getConfigRefetchDelay(),
  }), [
    publishChanges,
    getConfigRefetchDelay,
  ]);

  return (
    <div className="publish-wrapper">
      {areUnsavedChanges() && publishingState !== changeStatus.PUBLISHED && !publishError ? (
        <div className="publish-unsaved-error">
          <TextDiv>{translate('wl.publishing.unsavedChanges')}</TextDiv>
        </div>
      ) : (
        <div className="publish-error">
          {publishError && <TextDiv>{translate(publishError.message)}</TextDiv>}
          <FormError />
        </div>
      )}
      <Submit
        inputSelectorId="publish-button"
        buttonLabel={submitButtonText}
        showSuccessEffect={publishingState === changeStatus.PUBLISHED}
        disabled={publishingState === changeStatus.PUBLISHING || publishingState === changeStatus.PUBLISHED}
        onSubmitFunction={publishButtonFunction}
        publishing={publishingState === changeStatus.PUBLISHING}
      />
      <style jsx>
        {`
          .publish-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            position: relative;
            left: ${isNavBarV2() ? 0 : '65px'};
            z-index: 100;
          }

          .publish-error {
            margin-right: 15px;
            font-size: var(--font-size-error);
            color: var(--color-error-red);
          }

          .publish-unsaved-error {
            margin-right: 15px;
            font-size: var(--font-size-error);
            color: var(--color-error-red);
            text-align: center;
          }

          @media (max-width: 1120px) {
            .publish-unsaved-error {
              max-width: 10vw;
            }
          }
          @media (max-width: 768px) {

            .publish-wrapper {
              display: flex;
              flex-direction: row;
              align-items: center;
              z-index: 100;
            }

            .publish-unsaved-error {
              max-width: none;
            }
          }
        `}
      </style>
    </div>
  );
}

export default PublishButton;
