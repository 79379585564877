/* eslint-disable no-console */
import { useState } from 'react';

export const STORAGE_KEY_USER_INPUT = 'returns-portal-user-input';
export const STORAGE_KEY_SESSION_INFO = 'returns-portal-session-info';
export const STORAGE_KEY_AUTH_DATA = 'returns-portal-auth-data';
export const STORAGE_KEY_CARRIER_ADDRESSES_INFO = 'returns-portal-carrier-addresses';
export const STORAGE_KEY_SHOPIFY_SIGNUP = 'returns-portal-signup-steps';
export const SESSION_LIFE_TIME = 24 * 60 * 60 * 1000; // 24h

/**
 * Reads data from browser session storage
 *
 * @param {string} key storage key
 * @return {object} state object
 */
const getData = (key) => {
  try {
    if (typeof window !== 'undefined' && window.sessionStorage) {
      const data = window.sessionStorage.getItem(key);
      return JSON.parse(data);
    }
    return {};
  } catch (error) {
    console.error('Error reading session storage:', error);
    return {};
  }
};

/**
 * Writes data to browser session storage under a storage key.
 * @param {string} key storage key
 * @param {object} value state object
 */
const setData = (key, value) => {
  try {
    if (typeof window !== 'undefined' && window.sessionStorage) {
      window.sessionStorage.setItem(key, JSON.stringify(value));
    }
  } catch (error) {
    console.error('Error writing to session storage:', error);
  }
};

const clearSession = () => {
  try {
    if (!window?.sessionStorage) return;
    window.sessionStorage.clear();
  } catch (error) {
    console.error('Error clearing session storage:', error);
  }
};

let sessionInterval;

const validateSession = (companyId) => {
  const sessionInfo = getData(STORAGE_KEY_SESSION_INFO);
  if (sessionInfo) {
    const { companyId: companyInSession, startedAt } = sessionInfo;
    if (
      companyInSession !== companyId
      || Date.now() - startedAt > SESSION_LIFE_TIME
    ) {
      clearSession();
      clearInterval(sessionInterval);
    }
  }
};

const startSession = (companyId) => {
  const session = {
    companyId,
    startedAt: Date.now(),
  };
  validateSession(companyId);
  sessionInterval = setInterval(() => validateSession(companyId), 10000);
  setData(STORAGE_KEY_SESSION_INFO, session);
};

/**
 * Hook to use session data for AuthContext
 *
 * @return {Array} state object that is consumed by AuthContext and a setter
 */
export const useSessionAuthData = () => {
  const [sessionChecked, setSessionChecked] = useState(false);
  const setSessionState = (value) => setData(STORAGE_KEY_AUTH_DATA, value);
  let sessionState = {};
  if (!sessionChecked) {
    sessionState = getData(STORAGE_KEY_AUTH_DATA) || {};
    setSessionChecked(true);
  }
  return [sessionState, setSessionState];
};

export default {
  getData,
  setData,
  startSession,
  validateSession,
  clearSession,
};
