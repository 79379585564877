import React, {
  useState, useEffect, createContext, useContext,
} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { AuthContext } from '@/contexts/auth-context';
import { AdminContext } from '@/contexts/admin-context';
import { useRouter } from 'next/router';
import getEnvVar from '@/utilities/environment-variable';
import { isEmpty } from '@/utilities/validation';
import { CUSTOM_TEXT_API_VERSION } from '@/modules/page-customisation/utilities';
import getNavigationRoutes from './get-navigation-routes';

export const MerchantContext = createContext({});

const MerchantContextProvider = ({ children }) => {
  const router = useRouter();
  const {
    isUserLoggedIn, getToken, loggedInCompanyId,
    clearAuth,
  } = useContext(AuthContext);
  const {
    adminModeMerchantId, isAdminReviewMode, setAdminModeMerchantId, setForceNavV2,
  } = useContext(AdminContext);
  const [config, setConfig] = useState({});
  const [configIsLoading, setConfigIsLoading] = useState(false);
  const [remoteConfigFetched, setRemoteConfigFetched] = useState(false);
  const [remoteConfigUpdated, setRemoteConfigUpdated] = useState(false);

  const merchantId = isAdminReviewMode ? router.query?.merchantId?.toUpperCase() : router.query?.companyId?.toUpperCase();

  const localModeEnabled = getEnvVar('REACT_APP_LOCAL_CONFIG_MODE') || null;

  const getRemoteConfig = async () => {
    if ((merchantId === loggedInCompanyId) || adminModeMerchantId) {
      try {
        setRemoteConfigUpdated(false);
        setConfigIsLoading(() => true);
        const { data } = await axios.post('/api/merchant-config', {
          params: {
            merchant: merchantId || adminModeMerchantId,
            token: getToken(),
            localModeEnabled: typeof localModeEnabled === 'string' && localModeEnabled === 'true' ? Boolean(localModeEnabled) : null,
          },
        });
        if (merchantId === 'CHALOUB_DEMO') {
          setForceNavV2(true);
        }
        setConfig(data);
        setRemoteConfigFetched(true);
        setRemoteConfigUpdated(true);
        setConfigIsLoading(() => false);
      } catch (exception) {
        if (exception.response?.status === 401) {
          clearAuth();
          setAdminModeMerchantId(null);
        } else {
          setConfig({
            error: exception.message,
          });
        }
        setConfigIsLoading(() => false);
      }
    }
  };

  const getConfigValue = (key) => {
    // probably need to refactor this to use lodash get and instead of a key, use a path
    if (key in (config || {})) return config[key];
    if (key in (config?.applicationConfig || {})) return config.applicationConfig[key];
    if (key in (config?.applicationConfig?.config || {})) return config.applicationConfig.config[key];
    return null;
  };
  // only really useable when you have a key that is in the normal config AND the applicationConfig
  const getApplicationConfigValue = (key) => {
    if (key in (config?.applicationConfig || {})) return config.applicationConfig[key];
    if (key in (config?.applicationConfig?.config || {})) return config.applicationConfig.config[key];
    return null;
  };

  const getConsumerUrl = () => config?.consumerUrl;
  const getProductName = () => config?.carrier?.productName;
  const getCompanyId = () => config?.companyId;
  const getRootOrgId = () => config?.carrier?.rootOrgId;
  const getCarrierId = () => config?.carrierId;
  const getFeature = (featureName) => config?.features?.[featureName] ?? {};
  const getApplicationConfigVersion = () => config?.applicationConfig?.version ?? '1.0';
  const getDefaultWarehouse = () => {
    if (!isEmpty(config?.warehousesConfig)) {
      return config.warehousesConfig.find((location) => location.enabled && location.default);
    }
    return null;
  };
  const getWarehouse = (locationId) => config?.warehousesConfig?.find((warehouse) => locationId === warehouse.locationId);
  const getEmailPreviews = () => config?.emailPreviews ?? [];
  const getEmailPreviewsInternational = () => config?.emailPreviewsInternational ?? [];
  const getRulesByType = (ruleType) => {
    const rules = config?.rulesConfig?.filter((rule) => rule.ruleType === ruleType) ?? [];
    // sort rules by priority highest to lowest
    rules.sort((a, b) => b.rule.priority - a.rule.priority);
    return rules;
  };

  const getOfferedCarriers = () => config?.offeredCarriers;
  const getOfferedStoreNetworks = () => config?.offeredStoreNetworks;
  const getMerchantNetworks = () => config?.storeNetworkConfig?.filter((network) => network.enabled);
  const getMerchantCarriers = () => config?.carriersConfig;
  const getSelectedCarrier = (carrierId) => config?.carriersConfig?.find((carrier) => carrier.carrierCompanyId === carrierId) ?? {};
  const isPaymentMethodEnabled = config?.features?.paymentAndReasonCodes?.sections?.['payment-method'];
  const getCustomisationPageVersion = () => getFeature('customisation')?.version ?? CUSTOM_TEXT_API_VERSION.V1;
  const getNetworkCompanyId = () => config?.networkCompanyId;
  const getRefundsConfig = () => config?.refunds || {};
  const getWebhooksConfig = () => config?.webhooks || [];
  const isInstoreReturnsEnabled = Boolean(config?.inStoreReturnsForm) && Boolean(getApplicationConfigValue('inStoreReturnsForm'));
  const getInstoreReturnsConfig = () => config?.inStoreReturnsForm;
  const getMerchantNavBarVersion = () => getFeature('navigation')?.version;
  const getHomePickupConfig = () => config?.homePickup;
  const getCurrencyOverride = () => config?.currencyOverride;

  useEffect(() => {
    if (isUserLoggedIn && loggedInCompanyId) {
      void getRemoteConfig();
    }
  }, [isUserLoggedIn, loggedInCompanyId, merchantId]);

  // fetch merchant config in admin editing mode
  useEffect(() => {
    if (adminModeMerchantId) {
      void getRemoteConfig();
    }
    if (remoteConfigFetched && !adminModeMerchantId) {
      setRemoteConfigFetched(false);
      setConfig({});
    }
  }, [adminModeMerchantId]);

  return (
    <MerchantContext.Provider value={{
      merchantId,
      getRemoteConfig,
      configIsLoading,
      remoteConfigFetched,
      remoteConfigUpdated,
      getConfigValue,
      getConsumerUrl,
      getCompanyId,
      getRootOrgId,
      getCarrierId,
      getProductName,
      getFeature,
      getNavigationRoutes: () => getNavigationRoutes(config),
      getApplicationConfigVersion,
      getDefaultWarehouse,
      getEmailPreviews,
      getEmailPreviewsInternational,
      getRulesByType,
      getOfferedCarriers,
      getOfferedStoreNetworks,
      getMerchantNetworks,
      getMerchantCarriers,
      getSelectedCarrier,
      getWarehouse,
      isPaymentMethodEnabled,
      isInstoreReturnsEnabled,
      getCustomisationPageVersion,
      getNetworkCompanyId,
      getRefundsConfig,
      getWebhooksConfig,
      getInstoreReturnsConfig,
      getHomePickupConfig,
      getApplicationConfigValue,
      getMerchantNavBarVersion,
      getCurrencyOverride,
      ...config,
    }}
    >
      {children}
    </MerchantContext.Provider>
  );
};

MerchantContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MerchantContextProvider;
